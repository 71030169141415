import React from "react";
import {graphql} from "gatsby";
import { Main } from "components/pages/LessonsInfo";
import Layout from "layout";
import Seo from "seo";
import { Grid } from '@mui/material';

const BlogSinglePage = ({data}) => {
    
    // const syllabus = dat
    let { sqliteLessons, allSqliteLessons } = data;
        allSqliteLessons = allSqliteLessons.nodes
    const info = {  
        name: sqliteLessons.sub_name,
        teacher: sqliteLessons.teacher,
        place: sqliteLessons.place1,
        id: sqliteLessons.code,
        year : sqliteLessons.year,
    };

    React.useEffect(() => {
        typeof window !== "undefined" && typeof window.gtag  !== "undefined" &&
        window.gtag("event", 'view_lesson_info', {
          search_term: info.id,
          ...info,
        })
    }, [])
    

    return(
        <Layout>
            <Seo 
                title={`${info.name}`}
                description={`信州大学で開講される ${info.teacher} 先生による ${info.name} の授業レビュー情報です`}
            />
            
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={11}>
                    <Main info={info} lessons={allSqliteLessons} />
                </Grid>
            </Grid>

            
        </Layout>
    )
}

export const query = graphql`
  query ($id: String, $code: String) {
    sqliteLessons(code_year: { eq: $id}) {
        code
        year
        sub_name
        teacher
        place1
    }
    allSqliteLessons(filter: {codes: {glob: $code}}) {
        nodes {
            codes
            code_year
            sub_name
            teacher
            URL
            code
            day
            name
            place1
            place2
            term
            year
        }
    }
  }
`

export default BlogSinglePage;