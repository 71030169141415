import React, { useState , useEffect} from "react";
import { 
  // setCookies ,
  Chart, 
  Comment,
  Syllabus,
  Related,
  Review 
} from ".";
import { fetcher } from "./keys";
import ReviewSeo from "./ReveiwSeo";



const MyChart = ({info, lessons}) => {

  const [fetch, setFetch] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openReview, setOpenReview] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  console.log(info);
  // fetcher
  useEffect(() => {
    setIsLoading(true);

    fetcher(info).then(res => {
      console.log(res);
      setFetch(res);
    }).catch(err => {
      console.log(err)

    }).then(() => {
      setIsLoading(false);
    });

  }, [update])


  return (

    <>
      
      <ReviewSeo 
        info={info}
        lessons={lessons}
        isLoading={isLoading} 
        review={fetch}
      />

      <Chart
        info={info}
        lessons={lessons}
        isLoading={isLoading} 
        review={fetch}
        onReview = {setOpenReview}
      />

      <Comment
        cmt = {fetch?.cmt}
        isLoading={isLoading}
      /> 
      
      <Syllabus
        lessons={lessons}
      />

      
      <Related
        teacher={info.teacher} 
        name={info.name} 
        isLoading={isLoading}
        onReview = {setOpenReview}
      />
      
      

      <Review 
        open = {openReview}
        setOpen = {setOpenReview}
        baseData={info}
        Syllabus={lessons}
        onUpdate={() => setUpdate(prev => !prev)}
      /> 

    </>
  )
}



export default MyChart;