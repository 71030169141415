import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Snackbar } from '@mui/material';
import { Main as MainForm } from '../Review/'



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({open, setOpen, ...props}) {

  const [openSnack, setOpenSnack] = React.useState(false);
  const snackMsg = "送信完了！"
  console.log(props.baseData);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isSubmitted) => {
    setOpen(false);
    if (isSubmitted === true) {
      setOpenSnack(true);
    }
  };

  React.useEffect(() => {
    if (openSnack) setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  }, [openSnack])

  return (
    <div>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{minHeight: "100vh"}}
      >
        <AppBar sx={{ position: 'relative' }}>

          
          
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              レビュー
            </Typography>

          </Toolbar>
        </AppBar>

        <MainForm
          handleClose={handleClose} 
          {...props}
        />

      </Dialog>

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        message={snackMsg}
        sx={{ maxWidth: [256, 356] }}
      />
    </div>
  );
}