import React from "react";
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import { Divider } from "components/UIkit";
import { PaperBox } from "components/UIkit";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { htmlSpecialChars_decode } from "function/common";

const SyllabusCard = ({value: vals}) => {

  return (
    <Card>
    <CardContent sx={{pb:0}}>

      <Typography variant="subtitle1" component="div" sx={{mb: 1.5, mt:.4}}>
        {vals["name"]}
      </Typography>

      <Divider sx={{my: 1, height: '0 !important'}}/>

      <Typography variant="body2" sx={{pt: 1}}>
        開講期 : {vals["year"]}年度 {vals["開講期"]}
        <br />
        曜日時限 : {vals["day"]}
        <br />
        担当教員 : {vals["担当教員"]}
        <br />
        開講部局 : {vals["place2"]}
      </Typography>

    </CardContent>

    <CardActions>
      <Button
      sx={{ml: .5}}
      href={htmlSpecialChars_decode(vals.URL)}
      target="_blank" rel="noopener noreferrer"
      >
        シラバスを確認
        <OpenInNewIcon sx={{fontSize: 18, ml:0.5}} />
      </Button>
    </CardActions>
    </Card>
  )
  
}

export default function ({lessons}) {
  console.log(lessons);
  const curYearLessons = [];
  const prevYearLessons = [];
  const [expanded, setExpanded] = React.useState(false);


  lessons.forEach(vals => {
    if(vals["year"] == "2022") {
      curYearLessons.push(vals);
    } else {
      prevYearLessons.push(vals);
    }
  });

  return(
    <Grid
      container spacing={1.5}   
      justifyContent="center"
      alignItems="center"
      sx={{my: 0
      }}
    >
    <Grid item xs={12}>
      <PaperBox sx={{px: 3, py: 2}}>

      <Typography variant="subtitle1" component="h3" sx={{fontSize: '1.05rem'}}>
        授業一覧
      </Typography>

      <Divider sx={{my: 1}} />

      {
        curYearLessons.length !== 0 &&
        <Typography variant="subtitle1" component="h4" sx={{p: 1, fontSize: '1rem'}}>
          2022年度
        </Typography>
      }

      <Grid
        container spacing={1.5}   
        // justifyContent="center"
        alignItems="center"
      >

      {
        curYearLessons.map((vals, index) => {
          return(
            <Grid item xs={12} sm={6} md={4} key={`${index}sysys`}>

              <SyllabusCard value={vals} />


            </Grid>
          )
        })
      }

      {
        prevYearLessons.length !== 0 &&
        <Grid item xs={12}>
        
        <Accordion sx={theme => ({
          backgroundColor: theme.palette.action.disabledBackground,
          width: "100%",

        })}>
        
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>2021年度以前</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid
            container spacing={1.5}   
            // justifyContent="center"
            alignItems="center"
          >
          {
            prevYearLessons.map((vals, index) => {
              return(

                <Grid item xs={12} sm={6} md={4} key={index}>

                  <SyllabusCard value={vals} />

                </Grid>
              )
            })
          }
          </Grid>
          </AccordionDetails>
          </Accordion>
        </Grid>
      }

 






      </Grid>
      </PaperBox>
    </Grid>

    </Grid>
  )
}