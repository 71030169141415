import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FormPaper } from '.'



const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  flexWrap: 'wrap',
  justifyContent: 'left',
  alignItems: 'stretch',
  alignContent: 'flexStart',
  '& .MuiToggleButtonGroup-grouped': {
    transition: '0.6s',
    margin: theme.spacing(1.5),
    borderWidth: '1px !important',
    border: 'solid',
    // borderColor: theme.palette.secondary.main + '!important',
    // borderColor: theme.palette.error.main + '!important',
    background: theme.palette.action.hover,
    color: theme.palette.text.secondary,
    marginTop: 3,
    margin: '0px 10px 20px 10px !important',
    paddingLeft: 0,
    paddingRight: 0,


    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:hover': {
      // backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity),
      opacity: 0.8,
    },
    '&.Mui-selected': {
      background: theme.palette.secondary.main,
      color: theme.palette.text.primary,

    },
    '&.Mui-selected:hover': {
      background: theme.palette.secondary.main,
      opacity: 0.8,
    }
  },
}));

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({

}));

const Selections = props => {

  const [alignment, setAlignment] = React.useState('');
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    props.onChange(newAlignment, props.title);
  };
  const hasErr = props.hasErr;
  const borderColor = hasErr ? "error" : "secondary";

  return (
    <>
    <FormPaper title={props.title}>
    <StyledToggleButtonGroup
        size="small"
        value={alignment}
        exclusive
        onChange={handleAlignment}
        disabled={props.isSubmitting}
      >
        {
          props.values.map((value, index) => {
            return(
              <ToggleButton value={index} key={index}
                sx={theme => ({
                  width: ['calc(33% - 20px)', 'calc(20% - 20px)', 'calc(20% - 20px)'],
                  height:  ['45px', '40px', '40px'],
                  borderColor: theme.palette[borderColor].main + "!important",
                  
                })}
              >
                {value}
              </ToggleButton>
            )
          })
        }

      </StyledToggleButtonGroup>
    </FormPaper>
    
    </>
  )
}
export default Selections;