import React, { useState , useCallback, useEffect } from 'react';
import { TextField } from '@mui/material';
import { FormPaper } from '.'

const TextArea = props => {

  const [value, setValue] = useState("")

  useEffect(() => {
    props.onChange(value, "コメント");
  }, [value])

  const handleInputValue = useCallback( e => {
    setValue(e.target.value.trim());
  },[value],)


  return (
    <>
    <FormPaper title={"コメント"}>
      <TextField
        error={props.hasErr}
        id="outlined-multiline-static"
        hiddenLabel
        multiline
        rows={4}
        variant="filled"
        color="secondary"
        fullWidth
        sx={{
          backgroundColor: 'action.selectedOpacity',
        }}
        onChange={handleInputValue}
        disabled={props.isSubmitting}
      />
    </FormPaper>
    </>
  )
}

export default TextArea;