import React, { useState , useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider } from 'components/UIkit';
import { FormPaper } from '.'


const SPLITKEY = '=!=SPLIT=!='


const TextArea = props => {
  console.log(props);
  const [value, setValue] = useState()
  const [values, setValues] = useState('')

  const baseData = props.baseData;
  const Syllabus = props.Syllabus;

  useEffect(() => {
    console.log(values);
    if (value !== '') {
      props.onChange(values.year, "year");
      props.onChange(values.code, "code");
    }
  }, [values]);
  

  const handleChange = (event) => {
    const aryValue = event.target.value.split(SPLITKEY)
    setValues({
      code : aryValue[0],
      year : aryValue[1],
    });
    setValue(event.target.value);
  };

  const curYearLessons = [];
  let prevYearLessons = [];
  Syllabus.forEach(vals => {
    if(vals["year"] == "2022") {
      curYearLessons.push(vals);
    } else {
      prevYearLessons.push(vals);
    }
  });
  prevYearLessons = prevYearLessons.reverse();

  return (
    <>
    <FormPaper title="授業選択">
      <Box sx={{px:1}} >
        <FormControl fullWidth>
        <InputLabel id="select-label">授業選択</InputLabel>
          <Select
            labelId="select-label"
            id="demo-simple-select"
            label="授業選択"
            value={value}
            onChange={handleChange}
            error={props.hasErr}
            disabled={props.isSubmitting}
          >

            {
              curYearLessons.map((values, index) => {
                return (
                  <MenuItem value={values['code']+SPLITKEY+values['year']}
                    key={index+23} sx={{flexDirection: "column", alignItems: "start"}}
                    year={values['year']}
                    code={values['code']}
                  >
                    {values['name']} ({values['day']})

                    <br />
                    <Typography variant='subtitle2' sx={theme =>  ({color: theme.palette.text.secondary })}>
                      {values['year']}年度{values['term']} 
                    </Typography>
                  </MenuItem>
                )
              })
            }

            {
              prevYearLessons.length !== 0 &&
              <Box sx={{py: 1}} /> 
            }


            {
              prevYearLessons.map((values, index) => {
                return (
                  <MenuItem value={values['code']+SPLITKEY+values['year']}
                    key={index+23} sx={{flexDirection: "column", alignItems: "start"}}
                    year={values['year']}
                    code={values['code']}
                  >
                    {values['name']} ({values['day']})

                    <br />
                    <Typography variant='subtitle2' sx={theme =>  ({color: theme.palette.text.secondary })}>
                      {values['year']}年度{values['term']} 
                    </Typography>
                  </MenuItem>
                )
              })
            }

          </Select>
        </FormControl>
      </Box>
    </FormPaper>
    </>
  )
}

export default TextArea;