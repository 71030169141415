import React, { Component , useState , useEffect} from "react";
import { merge } from 'lodash';
import { BaseOptionChart } from "../../charts/"
// import Chart from "apexcharts";
import Loadable from '@loadable/component'
const  Chart = Loadable(() => import("react-apexcharts"));

export default function ApexChart ({isLoading, data = [0,0,0,0,0]}) {
  const baseOptionChart = BaseOptionChart();
  
  const [options, setOptions] = useState({});
  const [ave, setAve] = useState(0);

  const aveSet = () => {
    if (!isLoading) {
      const len = data.reduce((s, i) => s+i, 0);
      const dat = data.map((n,i) => n*i);
      const sum = dat.reduce((s,i) => s+i);
      setAve(sum*25/len);
    } else {
      setAve(-100);
    }
  }

  useEffect(() => {
    console.log(isLoading ? "isLoading" : "comp");
    aveSet();
    const initialOptions =
      merge(baseOptionChart, {

        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15
              }
            },
            dataLabels: {
              name: {
                offsetY: -10,
                fontSize: "13px"
              },
              value: {
                fontSize: "30px",
                show: true,
                formatter: function (val) {
                  if (val === -100) return "Loading..."
                  if (isNaN(val))  return "レビューなし";
                  if (val === 100) return "100点";
                  return val.toFixed(1) + '点';
                }
              },
              total: {
                show: false,
              },
            }
          },
          
        },
        legend: {
          show: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.5,
            // gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        }, 
        hollow: {
          margin: 0,
          size: "70%",
          background: "#293450"
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        stroke: {
          lineCap: "round"
        },
    
        labels: ['総合評価'],
    
      })
    
    setOptions(initialOptions);
  }, [isLoading, data])

  return (
    <div style={{
      minHeight: 326.65,
    }}>
      <Chart options={options} series={[ave]} type="radialBar" height={350} />
    </div>
  )
}