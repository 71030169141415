import React, { useState , useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Paper, TextField } from '@mui/material';

const MyPaper = props => {


  return (
    <>
    <Paper elevation={0} sx={(theme) => ({px: 2, backgroundColor: theme.palette.background.paper , borderRadius: 2})}>
    <Stack spacing={2}>
      <Typography variant="subtitle1" gutterBottom component="div" sx={{ml: 1, mb: 0}}>
        {props.title}
      </Typography>

      {props.children}

    </Stack>
    </Paper>
    </>
  )
}

export default MyPaper;