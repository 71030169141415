import React, {useState, useEffect} from "react";
import { Form } from ".";
import Grid from '@mui/material/Grid';





export default function (props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={11}>
        <Form  
          {...props}
        />
      </Grid>
    </Grid>
  )
}
