import React, { useCallback , useState , useEffect} from "react";
import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import { useLocation } from "@reach/router";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import { Fab } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useCookies } from "react-cookie";
import setCookies, { setBookmarkCookie , isSame, removeBookmarkCookie} from "./setCookies";

export default function ({info, onReview}) {

  const [cookies, setCookie] = useCookies();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("ブックマークに追加しました！");
  const location = useLocation();
  const { teacher, name, place, } = info;

  let isBookmark = false;
  if(cookies["bookmark"]) {
    cookies["bookmark"].forEach((val, index) => {
      if (isSame(val, info)) {
        isBookmark = true;
      }
    });
  }

  useEffect(() => {
    setCookies(setCookie, cookies["lessons"] ,  info, location.pathname);
  }, [])
  

  const handleAddBookmark = () => {

    setBookmarkCookie(setCookie, cookies["bookmark"] , info, location.pathname);
    setSnackbarOpen(true);
    setSnackMsg("ブックマークに追加しました！");
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 2000);
  }

  const handleRemoveBookmark = () => {
    removeBookmarkCookie(setCookie, cookies["bookmark"] , info, location.pathname);
    setSnackbarOpen(true);
    setSnackMsg("ブックマークから削除しました");
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 2000);

  }


  return(
    <>
      <Box sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
      <Box>
        <Box>
          <Typography variant="h5" component="h2" sx={{mb: 1}}>
            {name}
          </Typography>
          <Typography variant="body1" component="p">
            {teacher}
          </Typography>
          <Typography variant="body1" component="p" sx={{pt: .5}}>
            {place}
          </Typography>
        </Box>

        <Box sx={{m: 2}}>
          {/* <Divider sx={{height: '0 !important' }}/> */}
        </Box>

        <Box sx={{ '& > :not(style)': { m: 1 } }}>

          <Fab variant="extended"  size="small" aria-label="レビューする"
            onClick={onReview}
          >
            <EditIcon sx={{ mr: 1 }} />
            レビュー
          </Fab>


          {
            isBookmark ?

            <Fab variant="extended"  size="small"
              aria-label="ブックマークに追加"
              onClick={handleRemoveBookmark}
            >
              <BookmarkAddedIcon sx={{ mr: 1 }} color="primary"/>
              ブックマーク
            </Fab>

            :

            <Fab variant="extended"  size="small"
              aria-label="ブックマークに追加"
              onClick={handleAddBookmark}
            >
              <BookmarkAddIcon sx={{ mr: 1 }} />
              ブックマーク
            </Fab>
          }
            
          
        </Box>
      </Box>  
      </Box>

      <Snackbar
        open={snackbarOpen}
        message={snackMsg}
        sx={{ maxWidth: [256, 356] , bottom: 32}}
      />

    </>
  )
}