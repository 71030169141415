export const formKeys = {
  '総合評価': ['最悪', '悪', '普通', '良', '最良'],
  '内容の良さ': ['最悪', '悪', '普通', '良', '最良'],
  '単位所得' : ['最難', '難', '普通', '楽', '超楽'],
  '出席確認' : ['確認なし', '時々あり', '毎度確認', 'レポートで確認', 'その他'],
  '評価方法' : ['テストのみ', 'レポートのみ', 'テスト・レポート両方', 'その他・不明'],
  '課題の量' : ['多い', '適量', '少ない', 'ない'],
}


export const formTitleKeys = {
  'code': 'code',
  'year' : 'year', 
  '総合評価' : 'sgo',
  '内容の良さ' : 'con',
  '単位所得' : 'tan',
  '出席確認' : 'atd',
  '評価方法' : 'hyo',
  '課題の量' : 'kdi',
  'コメント' : 'cmt'
}

const initialGetValues = () => {
  const initialValues = {};
  Object.keys(formTitleKeys).forEach(value => { 
    initialValues[value] = null; //上のObj全部Nullちや！！！
  });
  return initialValues;
}

export const initialValues = initialGetValues();

const initialGetErrs = () => {
  const initialValues = {};
  Object.keys(formTitleKeys).forEach(value => { 
    initialValues[value] = false;
  });
  return initialValues;
}

export const initialErrs = initialGetErrs();

