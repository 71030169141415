import React from "react";
import Helmet from "react-helmet";

const ReviewSeo = ({info, review, isLoading, ...props }) => {

    if (isLoading) {
        return <></>
    }

    const {
        name, 
        teacher,
        place,
    } = info

    

    const rate = review?.chartdata?.総合評価 ?? [0,0,0,0,0]
    const len = rate.reduce((s, i) => s+i, 0);
    const dat = rate.map((n,i) => n*i);
    const sum = dat.reduce((s,i) => s+i);
    const ave = sum*25/len;

    console.log(rate);
    
    return (
        <Helmet 
            script={[{ 
                type: 'type="application/ld+json"', 
                innerHTML: `{
                    "@context": "https://schema.org/",
                    "@type": "AggregateRating",
                    "itemReviewed": {
                        "@type": "Course",
                        "name": "${name}",
                        "description": "信州大学で開講される ${teacher} 先生による ${name} - ${place}",
                        "provider": {
                          "@type": "Organization",
                          "name": "信州大学 - ${place}"
                        }
                    },
                    "ratingValue": "${ave}",
                    "worstRating": "0",
                    "bestRating": "100",
                    "ratingCount": "${len}"
                }
                `
            }]} 
        />
    )
}

export { ReviewSeo };
export default ReviewSeo