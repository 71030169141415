// import { chunk } from 'lodash';
export function divideArrIntoPieces(arr,n){
  var arrList = [];
  var idx = 0;
  while(idx < arr.length){
      arrList.push(arr.splice(idx,idx+n));
  }
  return arrList;
}

export function str2ary(str, n) {
  if ( n === undefined || n <= 1 ) return [...str];
  let strs = [];
  let array = [];
  [...str].map((key, $i) => { 
    if ($i%n==3) {
      array.push(key);
      strs.push(array);
      array = [];
    } else {
      array.push(key);
    }
  })
  strs.push(array);
  array = [];
  strs.map(str => {
    array.push(str.join(''))
  })
  return array;
}