import axios from "axios";
import { formTitleKeys } from "./keys";


export const submit = (data, baseData) => {
  
  const submitData = {...baseData};

  Object.keys(data).forEach(key => {
    submitData[formTitleKeys[key]] = data[key];
  });
  console.log(submitData);
  

  return  new Promise(function(resolve, reject) {
    
    axios.post('https://api.rakutan-alps.com/api/v2/review/', submitData)
    // axios.post('http://localhost:8040/api/v2/review/', submitData)
    .then(res => {
      if (res.data?.state != "ok") {
        reject(res.data.error);
      } else {
        resolve(res);
      }
    }).catch(err => {
      reject(err);
    });

  });;
}