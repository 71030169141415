import axios from "axios";
import { str2ary } from "function/array";

export const jaTitle = ["総合評価","単位取得","授業内容","課題の量","出席確認","採点方法"];

export const fetcher = ({id, year}) =>  new Promise((resolve, reject) => {

    axios.post('https://api.rakutan-alps.com/api/lessons/info/',{
    // axios.post('https://localhost:8040/api/lessons/info/',{
      id : id,
      year: year
    }).then(res => {

        console.log(res.data);
        if (res.data.error == "nodata") {
            return reject("nodata");
        } else {
            const data = res.data.review;
            const getChartData = {};
            const getCategories = {};

            jaTitle.map(jaKey => {
                getChartData[jaKey] = [];
                getCategories[jaKey] = [];

                Object.keys(data[jaKey]).map(key => {
                    const myKey = str2ary(key, 4);
                    getChartData[jaKey].push(data[jaKey][key]);
                    getCategories[jaKey].push(myKey);
                });

            })

            return resolve({
                cmt: res.data.cmt,
                categories: getCategories,
                chartdata: getChartData,
            })
        }
    })
})