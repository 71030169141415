import React from "react";
import { Grid } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Divider } from "components/UIkit";
import { PaperBox } from "components/UIkit";


export default function ({cmt = [], isLoading}) {
  const cmts = cmt;
  console.log(cmts);

  return (
    <Grid
      container spacing={1.5}   
      justifyContent="center"
      alignItems="center"
      sx={{my: 0}}
    >
      <Grid item xs={12}>
        <PaperBox sx={{px: 3, py: 2}}>

          <Typography variant="subtitle1" component="h3" sx={{fontSize: '1.05rem'}}>
            コメント
          </Typography>
          <Divider sx={{my: 1}}/>

          {
            cmts && cmts.length > 0 && cmts.map((vals, index) => {
              return (
                <React.Fragment key={(index + 13)*2}>
                <Box sx={{mx: [1, 2], mb: 3, mt: 1}}>
                
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'no-wrap',
                      listStyle: 'none',
                      pb: 1.5,
                    }}
                  >   
                  <Grid
                    container 
                    sx={{
                      width: ["100%", "80%", "60%" ]
                    }}
                    spacing={[.2, 2.5]}
                  >
                    {
                      Object.keys(vals).map((key, index) => {
                        if (key !== "コメント") {
                          return (
                            <Grid item xs={2} key={`my-New${index}`}>

                              <Box textAlign={"center"} sx={theme => ({background: theme.palette.background.default })} borderRadius={1} py={.5} px={[,]}>
                                <Box fontSize={".5rem"} sx={{transform: ["scale(.7)", 'scale(.8)']}} >
                                  {key}
                                </Box>
                                <Box fontSize={".5rem"} whiteSpace={"nowrap"} sx={{transform: ["scale(.9)", 'scale(1)']}}>
                                  {vals[key]}
                                </Box>
                              </Box>

                            </Grid>
                          )
                        }
                      })
                    }

                  </Grid>

                  </Box>

                  <Typography variant="body1" component="p" sx={{fontSize: '.9rem'}}>
                    {vals["コメント"]}
                  </Typography>

                </Box>
                <Divider sx={{my: 1, height: '0 !important'}}/>
                </React.Fragment>
              )
            })
          }

          {
            cmts.length < 1 &&
            <Typography variant="body1" component="p" sx={{fontSize: '.9rem'}}>
              {"コメントはありません..."}
            </Typography>
          }



        </PaperBox>
      </Grid>
    </Grid>
  )
}
