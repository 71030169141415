const cookieExpiration = '2030-11-11 23:59:59'
const cookieDate = new Date(cookieExpiration)

export function isSame(vals, cur) {
  return(
    vals.teacher === cur.teacher &&
    vals.name === cur.name &&
    vals.year === cur.year 
  )
}

export default function (setCookie, prevCookie, data, location) {

  const saveData = [];
  
  if (prevCookie) {
    prevCookie.forEach((val, index) => {
      console.log(isSame(val, data));
      if (!isSame(val, data)) {
        saveData.push(val);
      }
    });
  }

  const timestamp = Date.now();
  const curData = {
    name: data.name,
    teacher: data.teacher,
    year: data.year,
    location: location,
    timestamp: timestamp,
  };
  saveData.unshift(curData);  
  setCookie("lessons", saveData, { expires: cookieDate, path: '/' })

  return ;
}

export function setBookmarkCookie  (setCookie, prevCookie, data, location) {

  const saveData = [];

  if (prevCookie) {
    prevCookie.forEach((val, index) => {
      if (!isSame(val, data)) {
        saveData.push(val);
      }
    });
  }

  const timestamp = Date.now();
  const curData = {
    name: data.name,
    teacher: data.teacher,
    location: location,
    timestamp: timestamp,
    year: data.year,
  };

  saveData.unshift(curData); 
  setCookie("bookmark", saveData, { expires: cookieDate, path: '/' })
  return ;
}

export function removeBookmarkCookie  (setCookie, prevCookie, data, location) {

  const saveData = [];

  if (prevCookie) {
    prevCookie.forEach((val, index) => {
      if (!isSame(val, data)) {
        saveData.push(val);
      }
    });
  }

  console.log(saveData);
  setCookie("bookmark", saveData, { expires: cookieDate, path: '/' })
  return ;
}