import React, { Component , useState , useEffect} from "react";
import Grid from '@mui/material/Grid';
import { Box, Button, Typography } from "@mui/material";
import { FeedBack, PaperBox } from "components/UIkit";
import { Link as MuiLink } from "@mui/material";
import { navigate } from "@reach/router"
import {
  RateReview as RateReviewIcon,
} from '@mui/icons-material';
import { Link as GaLink } from "gatsby";

const Link = props => {
  const handleClick = e => {
    e.preventDefault();
    navigate(`${props.to}?query=${props.state?.query}`, {state :props.state});
  }
  return (
    <MuiLink
      sx={{color: "#0072E5", cursor: 'pointer', ...props.sx}}
      {...props} component={GaLink} onClick={handleClick}
    >
      {props.children}
    </MuiLink>
  )
}

const LinkBox = props => {
  return (
    <PaperBox sx={{px: 3, py: 3, height: 120}}>
      <Link underline="none" to={props.to} state={props.state}>
        {props.children}
      </Link>
    </PaperBox>
  )
}

const ReviewLinkBox = props => {

  return (
    <PaperBox sx={{px: 3, py: 3, height: 120}}>

      <Typography textAlign={"center"} variant="subtitle1" sx={{pb: 1}}>
        この授業をレビューする
      </Typography>
      <Box sx={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Button variant="outlined" startIcon={<RateReviewIcon />} 
          onClick={props.onClick}
        >
          レビューする
        </Button>
      </Box>
  
    </PaperBox>

  )
}

export default function (props) {
  const { name, teacher } = props ;

  return(
    <>
    <Grid
      container spacing={1.5}   
      justifyContent="center"
      alignItems="center"
      sx={{my: 0
      }}
    >

      <Grid item xs={12} sx={{display: {sm:'none'}}}>
        <ReviewLinkBox  onClick={() => props.onReview(true)} />
      </Grid>

      <Grid item xs={6} sm={4}>
        <LinkBox to={`/lessons/`} state={{query: teacher}} >
          「{teacher}」 を検索する
        </LinkBox>
      </Grid>

      <Grid item sm={4} sx={{display: {xs:'none', sm:'block'}}} >
        <ReviewLinkBox  onClick={() => props.onReview(true)} />
      </Grid>

      <Grid item xs={6} sm={4}>
        <LinkBox to={`/lessons/`} state={{query: name}} >
          「{name}」 を検索する
        </LinkBox>
      </Grid>

      <Grid item xs={12}>
        <FeedBack />
      </Grid>

    </Grid>


    </>
  )
}