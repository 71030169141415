import React from "react";
import { merge } from "lodash";
import { BaseOptionChart } from "../../charts"

import Loadable from '@loadable/component'
// import Chart from "apexcharts";
const  Chart = Loadable(() => import("react-apexcharts"), {
  fallback: <div style={{paddingTop: `65%`}}/>,
});

const MiniChart = ({
    data = [0,0,0,0,0],
    categories = [
        [""],
        [""],
        [""],
        [""],
        [""],
    ]
}) => {
    let max = data.reduce((sum, element) => sum + element, 0) ;
    max = Math.round((max + 1)/2) * 2
    const baseOptionChart = BaseOptionChart();
    const options =  
    merge(baseOptionChart,{
        chart: {
          type: 'bar',
          height: 150
        },
        tooltip: {
          marker: { show: false },
        },
        plotOptions: {
          bar: {
          //  horizontal: true, 
            barHeight: '20%', borderRadius: 2 ,
            barWidth: '60%',
          }
        },
        xaxis: {
          categories: categories,
          labels: {
            maxHeight: 40
          },
    
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          // tickAmount: max/2 + 1,
          labels: {
            // minWidth: 80,
            formatter: function(val) {
              return val.toFixed(0)
            }
          },
          max: max,
    
        },
        legend :{
          show: false
        }
    })
    
    
    return (
      <>
        <Chart type="bar" series={[{data: data}]} options={options} height={'auto'}/>
      </>
    )
}
export default MiniChart