import React from "react";

import Grid from '@mui/material/Grid';
import { MainChart } from './';
import { Box, Typography } from "@mui/material";
import { Divider } from "components/UIkit";
import { PaperBox } from "components/UIkit";
import { Divider as MuiDivider } from "@mui/material";
import NameArea from "./NameArea";
import { jaTitle } from "./keys";
import MiniChart from "./miniChart"

export default function MyApp({info, isLoading, review, onReview, ...props}) {

  return (
    <>
    <Grid
      container spacing={1.5}   
      justifyContent="center"
      alignItems="center"
    >
    {
      jaTitle.map((jaKey, index) => {
        return (
          <React.Fragment key={index + 23}>
            
            {
              (index === 0) &&
              (   
                <>
                  <Grid item xs={12}>
                  <PaperBox> 

                    <Grid container>
                      <Grid item xs={12} sm={5.95} md={3.95}>
                        <MainChart 
                          data={review?.chartdata ?  review["chartdata"][jaKey] : undefined}
                          isLoading={isLoading} 
                        />
                      </Grid>
                      

                      <MuiDivider variant="middle" 
                        orientation="vertical"
                        sx={{height:"auto !important", display:['none', 'block', null]}} 
                      />
                      <Box
                        sx={{mx: 3, width: '100%', display:['block', 'none', null]}}
                      >
                        <Divider/>
                      </Box>

                      <Grid item xs={12} sm={6} md={8}>
                        <NameArea info={info} onReview={onReview} />
                      </Grid>
                    </Grid>

                  </PaperBox>
                  </Grid>
                </>
              )
            }

            <Grid item xs={12} sm={6} md={4}>
              <PaperBox>
                <Box sx={{mx: 3, py: 2}}>

                  <Typography variant="subtitle1" component="h3" sx={{py: 1, fontSize: '1.05rem'}}>
                    {jaKey}
                  </Typography>
                  
                  <Divider/>
                </Box>
                <MiniChart 
                  data={review?.chartdata ?  review["chartdata"][jaKey] : undefined}
                  categories={review?.categories ? review["categories"][jaKey] : undefined}
                />
              </PaperBox>
            </Grid>

          </React.Fragment>
        )
      })
    }
    </Grid>
    </>
  )
}


