import React, { useState , useEffect} from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Divider from 'components/UIkit/Divider'
import { formKeys, initialValues, initialErrs} from './keys';
import { Selections, TextArea, SelectLesson } from  '.';
import { Snackbar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { submit } from './model';
import { Link } from "gatsby";

export default function CustomizedDividers(props) {

  const [formValue, setFormValue] = useState(initialValues);
  const [hasErrs, setHasErrs] = useState(initialErrs);
  const [openSnack, setOpenSnack] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const {
    baseData,
    handleClose ,
    onUpdate,
  } = props



  useEffect(() => {
    if (openSnack) setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  }, [openSnack])

  console.log(formValue);

  const handleValue = (value, jaTitle) => {
    setHasErrs(prevs => ({...prevs, [jaTitle]: false }))
    setFormValue(prevs => ({
      ...prevs,
      [jaTitle]: value,
    }));
  }

  const handleSubmit = (e) => {
    console.log("submit");

    // Valid function
    let isValid = true;
    Object.keys(formValue).forEach(key => {
      if (!formValue[key] && formValue[key] !== 0) {
        isValid = false;
        setHasErrs(prevs => ({...prevs, [key]: true }))
      };
    })

    console.log(isValid);
    if (!isValid) {
      setSnackMsg("未入力の項目があります")
      setOpenSnack(true);

    } else {
      // sucusess
      setIsSubmitting(true);
      submit(formValue, baseData).then(res => {
        setIsSubmitting(false);
        handleClose(true);
        onUpdate();
        window.scroll({
          top:0,
          behavior: 'instant'
        })
        
        setSnackMsg("完了しました！")
        setOpenSnack(true);
        
      }).catch(err => {
        setSnackMsg("問題が発生しました")
        setOpenSnack(true);
      })
    }
  }


  return (
    <Box
      sx={{ 
        '& > :not(style)': {
          my: 3,
        },
      }}
      component="form"
      // onSubmit={handleSubmit}
    >

    <SelectLesson 
      baseData={props.baseData}
      Syllabus={props.Syllabus}
      onChange={handleValue}
      isSubmitting={isSubmitting}
      hasErr={hasErrs["時間割コード"]}
    />

    <Divider sx={{height: '0 !important' }} />

      {Object.keys(formKeys).map((key, index) => {
        return(
          <div key={index+63}>
            <Selections
              values={formKeys[key]}
              title={key}
              Submitted={''}
              onChange={handleValue}
              hasErr={hasErrs[key]}
              isSubmitting={isSubmitting}
            />

            <Divider sx={{height: '0 !important' }} />
          </div>
        )
      })}

      <TextArea 
        onChange={handleValue}
        hasErr={hasErrs["コメント"]}
        isSubmitting={isSubmitting}
      />

      <Box sx={{ display: 'flex', p: 1, bgcolor: 'background.paper' }}>
        <Box flexGrow={1}>
          <Typography variant="overline" display="block" sx={{lineHeight: 'inherit'}}>
            送信により、<Link to={"/terms"}>利用規約</Link>に同意にしたものとします。
          </Typography>
        </Box>
        <LoadingButton 
          type='button'
          variant="outlined" size="large" 
          sx={{my: 0, minWidth: 80}} color="secondary"
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          送信
        </LoadingButton>

      </Box>
      <Box sx={{width: "100%", height: 32}} />


      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        message={snackMsg}
        sx={{ maxWidth: [256, 356] }}
      />

    </Box>
  );
}
